import React from 'react';

const Footer = () => {
    return (
        <>
      Maximiliano Peirano Campbell © {new Date().getFullYear()}
       </>
       
    )
}

export default Footer
